import {Link} from "react-router-dom";
import logo from "../../../../assets/logo.svg"
import styles from "../css/header.module.css"

export function HeaderLogo() {
    return (
        <Link to="/">
            <img src={logo} alt="logo" className={styles.header_logo}/>
        </Link>
    )
}