import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import majesticons_close from "../../../../assets/majesticons_close.svg";
import belt_100 from "../../../../assets/belt_100_percent.webp";
import styles from "./css/beltFull.module.css";


export function BeltFull({ isOpen, setIsOpen }: {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}) {
    function closeModal() {
        setIsOpen(false);
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <div className="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" />
                <div className="fixed inset-0 flex items-center justify-center">
                    <Dialog.Panel>
                        <div className={styles.modalContainer}>
                            <img src={belt_100} alt="Belt" className={styles.modalImage} />
                            <img src={majesticons_close} alt="Close" className={styles.modalClose} onClick={closeModal} />
                            <Dialog.Title as="h2" className={styles.modalTitle}>
                                Ваш пояс заполнен!
                            </Dialog.Title>
                            <Dialog.Description as="div" className={styles.modalDescription_wrap}>
                                <span className={styles.modalDescription}>Вы получили 1 удар! Вы можете потратить его на дополнительную игру.</span>
                            </Dialog.Description>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </Transition>
    );
}
