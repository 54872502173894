import styles from "../css/home.module.css"
import {Item} from "./item";
import { useState, useEffect } from "react";
import ApiClient from "../../../../api/basic";
import { PrizeItemType } from "../../../../types/prize/prizeItem";

export function Items({start, items}: {start: number, items: PrizeItemType[]}) {
    const offset = 9
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
    useEffect(() => {
        const checkScreenSize = () => {
            setIsSmallScreen(window.innerWidth <= 425);
        };

        checkScreenSize();

        window.addEventListener("resize", checkScreenSize);

        return () => {
            window.removeEventListener("resize", checkScreenSize);
        };
    }, []);
    return (
        <section className={styles.main_items_wrap}>
            <div className={styles.main_items}>
                {items &&
                    items.slice(start, start + (isSmallScreen ? 9 : 18)).map((item, index) =>
                        <Item key={index} {...item} size={isSmallScreen ? "small" : 'medium'}/>
                    )
                }
            </div>
        </section>
    )
}