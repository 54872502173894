import {LiveLenta} from "../../components/liveLenta/liveLenta";
import styles from "./css/home.module.css"
import {LeftWrap} from "./components/leftWrap";
import {RightWrap} from "./components/rightWrap";

export function HomePage() {
    return (
        <main className={styles.main}>
            <LiveLenta/>
            <div className={styles.left_right_wrap}>
                <LeftWrap/>
                <RightWrap/>
            </div>
        </main>
    )
}