import TelegramWidget from "../../components/widget/telegram/telegram";
import styles from "../noAuth/css/noAuth.module.css";

export const AuthPage = () => {
    return (
      <div className={styles.auth_page}>
        <div className={styles.auth_box}>
          <h1>Авторизуйтесь через Telegram</h1>
          <TelegramWidget/>
        </div>
      </div>
    );
  };