import {Dispatch, Fragment, SetStateAction, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {PrizeMain} from "./main";

export function PrizePopUp({setIsOpen, isOpen}: {setIsOpen: Dispatch<SetStateAction<boolean>>, isOpen: boolean}) {
    let [canClose, setCanClose] =  useState<boolean>(false)
    function closePopUp(){
        if (canClose){
            setIsOpen(false)
        }
    }
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closePopUp}>
                <div className="fixed inset-0 overflow-y-auto bg-opacity-40 bg-black">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel>
                                <PrizeMain setIsOpen={setIsOpen} isOpen={isOpen} canClose={canClose} setCanClose={setCanClose} closePopUp={closePopUp}/>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}