import {Item} from "./item";
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay} from 'swiper/modules';

import 'swiper/css';
import ApiClient from "../../../../api/basic";
import { useEffect, useState } from "react";
import { PrizeItemType } from "../../../../types/prize/prizeItem";
export function Items() {
    const apiClient = new ApiClient();
    const [items, setItems] = useState<PrizeItemType[]>([])
    useEffect(() => {
        apiClient.lastWinnerItems().then((resp) => {
            if (!resp.success) {
                console.log(`Error loading prizes: ${resp}`)
            }
            setItems(resp.data)
        }).catch((error) => {
            console.log(`Error loading prizes: ${error}`)
            setItems([])
        })
    }, [])
    return (
        <Swiper
            modules={[Autoplay]}
            breakpoints={{
                0: {
                    slidesPerView: 4,
                    spaceBetween: 2
                },
                700: {
                    slidesPerView: 5,
                    spaceBetween: 0
                },
                900: {
                    slidesPerView: 6,
                    spaceBetween: 0
                },
                1100: {
                    slidesPerView: 7,
                    spaceBetween: 0
                },
                1300: {
                    slidesPerView: 8,
                    spaceBetween: 0
                },
                1500: {
                    slidesPerView: 9,
                    spaceBetween: 0
                },
                1690: {
                    slidesPerView: 10,
                    spaceBetween: 0
                },
                1920: {
                    slidesPerView: 12,
                    spaceBetween: 0
                },
                2380: {
                    slidesPerView: 16,
                    spaceBetween: 1
                }
            }}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            autoplay={{ delay: 2500 }}
        >
            {
                items.map((item, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <Item name={item.name} path_to_file={item.path_to_file} color={item.color}/>
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
    )
}