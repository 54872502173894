import rub from "../../../../assets/rub.svg"
import useAccountStore from "../../../../store/account";
import styles from "../css/header.module.css"

export function BalanceWallet(){
    const accountStore = useAccountStore();
    const telegramBotLink = `https://t.me/` + process.env.REACT_TELEGRAM_BOT
    return (
        <div className={styles.wallet_wrap}>
            <div className={styles.wallet_wrap_item_top}>
                <span className={styles.wallet_wrap_item_top_mobile_text}>Ваш баланс:</span>
                <img src={rub} alt="rub"/>
                <span className={styles.wallet_wrap_item_top_text}>{Math.round(accountStore.user.balance)}</span>
            </div>
            <a className={styles.wallet_wrap_item_bottom_text} href={telegramBotLink}>Вывести</a>
        </div>
    )
}