import styles from "./css/liveLenta.module.css"
import {Logo} from "./components/logo";
import {Items} from "./components/items";
export function LiveLenta() {
    return (
        <article className={styles.liveLenta_wrap}>
            <Logo/>
            <Items/>
        </article>
    )
}