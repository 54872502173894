import {HeaderLogo} from "./components/headerLogo";
import {HeaderNav} from "./components/headerNav";
import {Belt} from "./components/belt";
import {BalancePoints} from "./components/balancePoints";
import {BalanceWallet} from "./components/balanceWallet";
import {Profile} from "./components/profile";
import styles from "./css/header.module.css"
import TelegramWidget from "../widget/telegram/telegram";
import useAccountStore from "../../../store/account";
import { MobileMenu } from "./components/mobileMenu";

export function Header() {
    const is_auth = useAccountStore((state) => state.is_auth);
    return (
        <header className={styles.header}>
            <div className={styles.logo_nav_wrap}>
                <div className={styles.logo_nav_mobile_wrap}>
                    <MobileMenu/>
                    <HeaderLogo/>
                </div>
                <HeaderNav/>
            </div>
            {is_auth && 
            <>
            <Belt/>
            <div className={styles.user_balance_wrap}>
                <div className={styles.balance_wrap}>
                    <BalancePoints/>
                    <BalanceWallet/>
                </div>
                <Profile/>
            </div>
            </>}
        </header>
    )
}