import {LiveLenta} from "../../components/liveLenta/liveLenta";
import styles from "./css/instruction.module.css"
import instruction from "../../../assets/instruction.svg"
import box_bg from "../../../assets/box_bg.webp";

export function InstructionPage() {
    return (
        <main>
            <LiveLenta/>
            <img src={box_bg} alt="box_bg" className={styles.box_bg}/>
            <div className={styles.instruction_header_wrap}>
                <img src={instruction} alt="instruction"/>
                <h1 className={styles.instruction_header}>Инструкция</h1>
            </div>
            <section className={styles.instruction_wrap}>
                <div>
                    <p className={styles.instruction_h1}>1. Introduction</p>
                    <span className={styles.instruction_span}>
                        We, INTERNATIONAL BUSINESS SYSTEMS S.R.L. operate the website https://lis-skins.ru/. This Privacy Policy is intended for users’ enlightenment and describes how we process users’ data and what rights users have as data subject.
INTERNATIONAL BUSINESS SYSTEMS S.R.L., is a company incorporated in the Singapore with registration number 202016923N and registered office at 10 Anson Road #20-05 International Plaza, Singapore.
We at INTERNATIONAL BUSINESS SYSTEMS S.R.L. are dedicated to safeguarding and preserving Your personal data and privacy when visiting Our Website, utilising Our services, products or communicating electronically with Us.
For the purposes of the data protection laws that apply to Us, including the GDPR which is the European General Data Protection Regulation and PDPA, which is the Singapore Personal Data Protection Act, We act as the Data Controller for the personal data that We collect and process to enable You to make use of Our Services.
The purpose of this Privacy Policy is to transparently provide You with an explanation of the legal basis for Us collecting and processing Your personal data, the categories of personal data that We may collect about you, what happens to the personal data that We may collect, where We process Your personal data, how long We are required to retain Your personal data, who We may share Your personal data with and to also explain Your rights as a data subject.
We update this Privacy Policy from time to time and will post all updates to Our Website as and when issued. Please do review this policy regularly on Our Website for any changes.
                    </span>
                </div>
                <div>
                    <p className={styles.instruction_h1}>2. Definitions</p>
                    <span className={styles.instruction_span}>
                        In this Privacy Policy the following terms shall have the following meanings:
“Cookies” mean small text files placed on Your computer or device by Our Website when You visit certain parts of Our Website.
“Consent” of Personal data procession shall mean any freely given, specific, informed and unambiguous indication of the Data Subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.
“GDPR” means the General Data Protection Regulation (EU) 2016/679, of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of Personal Data and on the free movement of such data, and repealing Directive 95/46/EC, as amended, replaced or superseded and in force from time to time and as transposed into member-state legislation.
“PDPA” means the Personal Data Protection Act (No.26 of 2012) of Singapore, which aims to govern the collection, use and disclosure of personal data by organisations, and to establish Do Not Call Register and to provide for its administration, and for matters connected therewith, and to make related and consequential amendments to various other Acts.
                    </span>
                </div>
            </section>
        </main>
    )
}