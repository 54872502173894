import styles from "./css/prize.module.css"
import { PrizeItem } from "./prizeItem";
import { PrizeSectionType } from "../../../types/prize/prizeSection";


export const PrizeSection = ({sectionLvl, isLocked, prizes}: PrizeSectionType) => {
    return (
        <div className={styles.section_item_wraps}>
                    <div>
                        <div className={styles.section_item_top_wrap}>
                            <span className={styles.section_item_top_text}>Призы для {sectionLvl} уровня подписки</span>
                            {isLocked ? <span className={styles.section_item_top_text_warning}>( Недоступно! )</span> : null}
                        </div>
                        <div className={styles.section_items_wrap}>
                            <div className={styles.section_items_wrap_grid}>
                                {prizes.map((prize) => <PrizeItem isLocked={isLocked} name={prize.name} path_to_file={prize.path_to_file} color={prize.color}/>)}
                            </div>
                        </div>
                        {isLocked ? <div className={styles.form_subs_div_wrap}>
                            <div className={`${styles.form_subs_wrap}`}>
                                <a className={styles.form_subs_text} href="https://t.me/dev_cybersport_bot" target="_blank">Оформить подписку</a>
                            </div>
                        </div> : null}
                    </div>
                </div>
    )
}