import { useEffect, useState } from 'react';
import ApiClient from '../../../../api/basic';
import hero from '../../../../assets/hero.png';
import hp25 from '../../../../assets/hp_25.png';
import hp50 from '../../../../assets/hp_50.png';
import hp75 from '../../../../assets/hp_75.png';
import hp100 from '../../../../assets/hp_100.png';
import styles from "../css/home.module.css"
import useAccountStore from '../../../../store/account';

const getStateFighter = (state: string): string => {
    switch (state) {
        case "0":
            return hp100
        case "1":
            return hp75
        case "2":
            return hp50
        case "3":
            return hp25
        default:
            return ""
    }
}


export function RightWrap(){
    const apiClient = new ApiClient();
    const [path, setPath] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [lvl, setLvl] = useState<string>("");
    const [name, setName] = useState<string>("");

    useEffect(() => {
        apiClient.getFighter().then(resp => {
            if (!resp.success) {
                console.log(`Error load fighters: ${resp}`)
                return
            }
            setPath(resp.data.path)
            setState(resp.data.state)
            setLvl(resp.data.lvl)
            setName(resp.data.name)
            console.log(resp)
        })
    }, [])
    return (
        <section className={styles.right_wrap}>
            <div className={`${styles.fighter_meta_wrap}`}>
                <span className={styles.fighter_meta_name}>{name}</span>
                <span className={styles.fighter_meta_lvl}>{lvl} уровень</span>
            </div>
            {/* <img src={fighter_meta} alt={state} cl/> */}
            <img src={getStateFighter(state)} alt={state}/>
            <img src={`${process.env.REACT_APP_API}/${path}`} alt="hero" className={styles.fighter_image}/>
        </section>
    )
}