import styles from "../css/home.module.css";
import to_right from "../../../../assets/to_right.svg";
import scroll_left from "../../../../assets/scroll_left.svg";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

export function Pagination({
    start,
    setStart,
    countItems,
}: {
    start: number;
    setStart: React.Dispatch<React.SetStateAction<number>>;
    countItems: number;
}) {
    const itemsPerPage = 9;
    const totalPages = Math.ceil(countItems / itemsPerPage);

    const handlePrevClick = () => {
        if (start > 0) {
            setStart((prev) => Math.max(prev - itemsPerPage, 0));
        }
    };

    const handleNextClick = () => {
        if (start + itemsPerPage < countItems) {
            setStart((prev) => Math.min(prev + itemsPerPage, (totalPages - 1) * itemsPerPage));
        }
    };

    return (
        <section className={styles.pagination_wrap}>
            <div className={styles.pagination_items_wrap}>
                <div className={styles.pagination_items}>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <div
                            key={index}
                            className={`${styles.pagination_items_item} ${
                                index * itemsPerPage !== start ? styles.pagination_items_item_inactive : ""
                            }`}
                        ></div>
                    ))}
                </div>
                <div className={styles.pagination_wrap_bottom}>
                    <img
                        src={to_right}
                        alt="left"
                        className={`${styles.right_scroll} ${styles.btn_scroll} ${start === 0 ? "" : styles.pagination_items_item_inactive}`}
                        onClick={handlePrevClick}
                    />
                    <img
                        src={to_right}
                        alt="right"
                        className={` ${styles.btn_scroll} ${
                            start + itemsPerPage >= countItems ? "" : styles.pagination_items_item_inactive
                        }`}
                        onClick={handleNextClick}
                    />
                </div>
            </div>
            <div className={styles.view_all_wrap}>
                <Link to="/prizes">
                    <span className={styles.view_all}>Смотреть все</span>
                </Link>
                <img src={scroll_left} alt="view all" />
            </div>
        </section>
    );
}
