import { useEffect, useState } from "react";
import styles from "./css/leaders.module.css";
import ApiClient from "../../../api/basic";
import firstLeader from "../../../assets/frst_leader.png"
import secondLeader from "../../../assets/scnd_leader.png"
import threeLeader from "../../../assets/thr_leader.png"
import { LeadersTimeType, LeadersType, LeaderType } from "../../../types/leader/leader";

const Leader = ({index, username, countPoints, countFights}: LeaderType) => {
    let srcNumber = ""
    let classColor = "" 
    switch (index){
        case 1:
            srcNumber = firstLeader
            classColor = styles.leaders_table_row_frst_item_text_gold
            break
        case 2:
            srcNumber = secondLeader
            classColor = styles.leaders_table_row_frst_item_text_silver
            break
        case 3:
            srcNumber = threeLeader
            classColor = styles.leaders_table_row_frst_item_text_bronze
            break
    }
    return (
        <div className={styles.leaders_table_row}>
            <div className={styles.leaders_table_row_frst_item}>
                {srcNumber && <img src={srcNumber} className={styles.leaders_table_row_frst_item_img} alt="number"/>}
                <span className={`${styles.leaders_table_row_frst_item_text} ${classColor}`} style={{height: "auto"}}>{index}</span>
            </div>
            <span className={styles.leaders_table_row_scnd_item_text}>{username}</span>
            <span className={styles.leaders_table_row_scnd_item_text}>{countPoints}</span>
            <span className={styles.leaders_table_row_scnd_item_text}>{countFights}</span>
        </div>
    )
}

export const Leaders = () => {
    const apiClient = new ApiClient();
    const [period, setPeriod] = useState<LeadersTimeType>("all");
    const [leaders, setLeaders] = useState<LeadersType>([]);

    const loadLeaders = async (timePeriod: LeadersTimeType) => {
        const resp = await apiClient.loadLeaders({period: timePeriod});
        if (!resp.success) {
            console.log(`Error load leaders: ${resp}`)
            return []
        }
        setLeaders(resp.data);
    }
    useEffect(() => {
       loadLeaders(period)
    }, [period])

    return (
        <section className={styles.leaders_wrap}>
            <div className={styles.leaders_period_wrap}>
                <div>
                    <span className={styles.leaders_period_text} onClick={() => setPeriod("all")}>За все время</span>
                    {period === "all" && <div className={styles.leaders_period_text_active}/>}
                </div>
                <div>
                    <span className={styles.leaders_period_text} onClick={() => setPeriod("month")}>За месяц</span>
                    {period === "month" && <div className={styles.leaders_period_text_active}/>}
                </div>
                <div>
                    <span className={styles.leaders_period_text} onClick={() => setPeriod("week")}>За неделю</span>
                    {period === "week" && <div className={styles.leaders_period_text_active}/>}
                </div>
            </div>
            <div className={styles.leaders_table_header}>
                <span className={styles.leaders_table_header_text}>РАНГ</span>
                <span className={styles.leaders_table_header_text}>НИКНЕЙМ</span>
                <span className={styles.leaders_table_header_text}>КОЛИЧЕСТВО НАБРАННЫХ ОЧКОВ</span>
                <span className={styles.leaders_table_header_text}>КОЛИЧЕСТВО УГАДАННЫХ БОЕВ</span>
            </div>
            <div className={styles.leaders_table_body}>
                {leaders.map((value, index) => <Leader {...value} key={index}/>)}
            </div>
        </section>
    )
}