import scrollLeft from "../../../assets/scroll_left.svg"
import gift from "../../../assets/gift.svg"
import {Link} from "react-router-dom";
import styles from "./css/prize.module.css"
import box_bg from "../../../assets/box_bg.webp"
import { PrizeSection } from "./prizeSection";
import ApiClient from "../../../api/basic";
import { useEffect, useState } from "react";
import { PrizeType } from "../../../types/prize/prize";

export function PrizePage() {
    const apiClient = new ApiClient();
    const [prizeSection, setPrizeSection] = useState<PrizeType | null>(null)
    useEffect(() => {
        apiClient.getPrizes().then((resp) => {
            setPrizeSection(resp)
        }).catch((error) => {
            console.log(`Error loading prizes: ${error}`)
            setPrizeSection(null)
        })
    }, [])
    return (
        <main className={styles.main_wrap}>
            <img src={box_bg} alt="box_bg" className={styles.box_bg}/>
            <div className={styles.return_to_main_wrap}>
                <Link to="/" className={styles.return_to_main_link}>
                    <img src={scrollLeft} alt="scrollLeft" className={styles.return_to_main_img}/>
                    <span className={styles.return_to_main_text}>Вернутся на главную</span>
                </Link>
            </div>
            <div className={styles.gift_wrap}>
                <img className={styles.gift_img} src={gift} alt="gift"/>
                <h1 className={styles.gift_text}>ВСЕ ПРИЗЫ</h1>
            </div>
            <section className={styles.section_lvl_wrap}>
                {prizeSection?.data?.sections ? prizeSection.data.sections.map((section) => <PrizeSection isLocked={section.isLocked} sectionLvl={section.sectionLvl} prizes={section.prizes}/>) : <h1>Loading... Prizes</h1>}
            </section>
        </main>
    )
}