import styles from "./css/prize.module.css"
import lock from "../../../assets/lock.png"
import { PrizeItemType } from "../../../types/prize/prizeItem";


export const PrizeItem = ({name, path_to_file, color, isLocked = true}: PrizeItemType & {isLocked?: boolean}) => {
    let style_bg = styles[`section_item_wrap_${color}`]
    let style_btm_bg = styles[`section_item_bottom_${color}`]
    return (
        <div className={`${styles.section_item_wrap} ${style_bg}`}>
                                <img src={`${process.env.REACT_APP_API}/${path_to_file}`} alt="image" className={styles.section_item_wrap_img}/>
                                <div className={`${styles.section_item_bottom} ${style_btm_bg}`}>
                                    <span className={styles.section_item_bottom_text}>{name}</span>
                                </div>
                                {isLocked ? <div className={styles.section_item_block}>
                                    <img className={styles.section_item_block_lock_img} src={lock} alt="lock"/>
                                </div> : null}
                            </div>
    )
}