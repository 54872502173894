import create from 'zustand';
import { TelegramUserType } from '../types/user/telegramUser';
import { UserType } from '../types/user/user';


interface AccountState {
  is_auth: boolean;
  user: UserType
  telegrmUser?: TelegramUserType
  setIsAuth: (value: boolean) => void;
  setTelegramUser: (value: TelegramUserType) => void;
  setUser: (value: UserType) => void;
}

// Создайте хранилище с типизацией
const useAccountStore = create<AccountState>((set) => ({
  is_auth: false,
  user: {
    username: '',
    telegram_id: 0,
    is_bot: false,
    accepted_rules: false,
    balance: 0,
    score: 0,
    language_code: '',
    created_at: '',
    is_pinned: false,
    coins: 0,
    avatar: '',
  },
  telegrmUser: {
    first_name: '',
    last_name: '',
    id: '',
    username: '',
    auth_date: '',
    hash: '',
    photo_url: '',
  },
  setIsAuth: (value) => set((state) => ({ is_auth: value })),
  setTelegramUser: (value) => set((state) => ({ telegrmUser: value })),
  setUser: (value) => set((state) => ({ user: value })),
}));

export default useAccountStore;