import { useState, useEffect } from "react";
import belt_100 from "../../../../assets/belt_100_percent.webp";
import belt_75 from "../../../../assets/belt_75_percent.webp";
import belt_50 from "../../../../assets/belt_50_percent.webp";
import belt_25 from "../../../../assets/belt_25_percent.webp";
import belt_0 from "../../../../assets/belt_0_percent.webp";
import useAccountStore from "../../../../store/account";
import styles from "../css/header.module.css";

export function Belt({ showOnMobile = false }: { showOnMobile?: boolean }) {
    const accountStore = useAccountStore();
    const coins = accountStore.user.coins % 1000;
    const [isMobileView, setIsMobileView] = useState(false);

    let beltImage;
    if (coins > 750) {
        beltImage = belt_100;
    } else if (coins > 500) {
        beltImage = belt_75;
    } else if (coins > 250) {
        beltImage = belt_50;
    } else if (coins === 0) {
        beltImage = belt_0;
    } else {
        beltImage = belt_25;
    }

    useEffect(() => {
        function handleResize() {
            setIsMobileView(window.innerWidth < 450);
        }

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (isMobileView && !showOnMobile) {
        return null;
    }

    return (
        <div className={styles.belt_wrap}>
            <img src={beltImage} alt="belt" />
        </div>
    );
}
