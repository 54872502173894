import closed_menu from "../../../assets/closed_menu.svg"
import opened_menu from "../../../assets/opened_menu.svg"
import faq from "../../../assets/faq.svg"
import styles from "./css/faq.module.css"
import {useState} from "react";
import {LiveLenta} from "../../components/liveLenta/liveLenta";
import box_bg from "../../../assets/box_bg.webp";

export function FaqPage() {
    const [indexOpen, setIndexOpen] = useState(0)
    return (
        <main>
            <LiveLenta/>
            <img src={box_bg} alt="box_bg" className={styles.box_bg}/>
            <div className={styles.faq_header_wrap}>
                <img src={faq} alt="faq"/>
                <h1 className={styles.faq_header}>FAQ</h1>
            </div>
            <section className={styles.faq_section_wrap}>
                <div>
                    <div className={styles.faq_section} onClick={() => setIndexOpen(indexOpen === 1 ? 0 : 1)}>
                        <img className={styles.faq_section_svg} src={indexOpen === 1 ? opened_menu : closed_menu} alt="select"/>
                        <p className={styles.faq_section_span}>Подождите немного, идет розыгрыш приза?</p>
                    </div>
                    {indexOpen === 1 ?
                        <div className={styles.faq_section_btm_wrap}>
                            <span className={styles.faq_section_btm_wrap_span}>Банальные, но неопровержимые выводы, а также интерактивные прототипы превращены в посмешище, хотя само их существование приносит несомненную пользу обществу. Однозначно, сторонники тоталитаризма в науке набирают популярность среди определенных слоев населения, а значит, должны быть смешаны с не уникальными данными до степени совершенной неузнаваемости, из-за чего возрастает их статус бесполезности. Банальные, но неопровержимые выводы, а также действия представителей оппозиции призывают нас к новым свершениям, которые, в свою очередь, должны быть объективно рассмотрены соответствующими инстанциями. А ещё реплицированные с зарубежных источников, современные исследования, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут в равной степени предоставлены сами себе. Следует отметить, что современная методология разработки позволяет оценить значение поэтапного и последовательного развития общества.</span>
                        </div> : null}
                </div>
                <div>
                    <div className={styles.faq_section} onClick={() => setIndexOpen(indexOpen === 2 ? 0 : 2)}>
                        <img className={styles.faq_section_svg} src={indexOpen === 2 ? opened_menu : closed_menu} alt="select"/>
                        <p className={styles.faq_section_span}>Подождите немного, идет розыгрыш приза?</p>
                    </div>
                    {indexOpen === 2 ?
                        <div className={styles.faq_section_btm_wrap}>
                            <span className={styles.faq_section_btm_wrap_span}>Банальные, но неопровержимые выводы, а также интерактивные прототипы превращены в посмешище, хотя само их существование приносит несомненную пользу обществу. Однозначно, сторонники тоталитаризма в науке набирают популярность среди определенных слоев населения, а значит, должны быть смешаны с не уникальными данными до степени совершенной неузнаваемости, из-за чего возрастает их статус бесполезности. Банальные, но неопровержимые выводы, а также действия представителей оппозиции призывают нас к новым свершениям, которые, в свою очередь, должны быть объективно рассмотрены соответствующими инстанциями. А ещё реплицированные с зарубежных источников, современные исследования, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут в равной степени предоставлены сами себе. Следует отметить, что современная методология разработки позволяет оценить значение поэтапного и последовательного развития общества.</span>
                        </div> : null}
                </div>
                <div>
                    <div className={styles.faq_section} onClick={() => setIndexOpen(indexOpen === 3 ? 0 : 3)}>
                        <img className={styles.faq_section_svg} src={indexOpen === 3 ? opened_menu : closed_menu} alt="select"/>
                        <p className={styles.faq_section_span}>Подождите немного, идет розыгрыш приза?</p>
                    </div>
                    {indexOpen === 3 ?
                        <div className={styles.faq_section_btm_wrap}>
                            <span className={styles.faq_section_btm_wrap_span}>Банальные, но неопровержимые выводы, а также интерактивные прототипы превращены в посмешище, хотя само их существование приносит несомненную пользу обществу. Однозначно, сторонники тоталитаризма в науке набирают популярность среди определенных слоев населения, а значит, должны быть смешаны с не уникальными данными до степени совершенной неузнаваемости, из-за чего возрастает их статус бесполезности. Банальные, но неопровержимые выводы, а также действия представителей оппозиции призывают нас к новым свершениям, которые, в свою очередь, должны быть объективно рассмотрены соответствующими инстанциями. А ещё реплицированные с зарубежных источников, современные исследования, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут в равной степени предоставлены сами себе. Следует отметить, что современная методология разработки позволяет оценить значение поэтапного и последовательного развития общества.</span>
                        </div> : null}
                </div>
                <div>
                    <div className={styles.faq_section} onClick={() => setIndexOpen(indexOpen === 4 ? 0 : 4)}>
                        <img className={styles.faq_section_svg} src={indexOpen === 4 ? opened_menu : closed_menu} alt="select"/>
                        <p className={styles.faq_section_span}>Подождите немного, идет розыгрыш приза?</p>
                    </div>
                    {indexOpen === 4 ?
                        <div className={styles.faq_section_btm_wrap}>
                            <span className={styles.faq_section_btm_wrap_span}>Банальные, но неопровержимые выводы, а также интерактивные прототипы превращены в посмешище, хотя само их существование приносит несомненную пользу обществу. Однозначно, сторонники тоталитаризма в науке набирают популярность среди определенных слоев населения, а значит, должны быть смешаны с не уникальными данными до степени совершенной неузнаваемости, из-за чего возрастает их статус бесполезности. Банальные, но неопровержимые выводы, а также действия представителей оппозиции призывают нас к новым свершениям, которые, в свою очередь, должны быть объективно рассмотрены соответствующими инстанциями. А ещё реплицированные с зарубежных источников, современные исследования, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут в равной степени предоставлены сами себе. Следует отметить, что современная методология разработки позволяет оценить значение поэтапного и последовательного развития общества.</span>
                        </div> : null}
                </div>
                <div>
                    <div className={styles.faq_section} onClick={() => setIndexOpen(indexOpen === 5 ? 0 : 5)}>
                        <img className={styles.faq_section_svg} src={indexOpen === 5 ? opened_menu : closed_menu} alt="select"/>
                        <p className={styles.faq_section_span}>Подождите немного, идет розыгрыш приза?</p>
                    </div>
                    {indexOpen === 5 ?
                        <div className={styles.faq_section_btm_wrap}>
                            <span className={styles.faq_section_btm_wrap_span}>Банальные, но неопровержимые выводы, а также интерактивные прототипы превращены в посмешище, хотя само их существование приносит несомненную пользу обществу. Однозначно, сторонники тоталитаризма в науке набирают популярность среди определенных слоев населения, а значит, должны быть смешаны с не уникальными данными до степени совершенной неузнаваемости, из-за чего возрастает их статус бесполезности. Банальные, но неопровержимые выводы, а также действия представителей оппозиции призывают нас к новым свершениям, которые, в свою очередь, должны быть объективно рассмотрены соответствующими инстанциями. А ещё реплицированные с зарубежных источников, современные исследования, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут в равной степени предоставлены сами себе. Следует отметить, что современная методология разработки позволяет оценить значение поэтапного и последовательного развития общества.</span>
                        </div> : null}
                </div>
                <div>
                    <div className={styles.faq_section} onClick={() => setIndexOpen(indexOpen === 6 ? 0 : 6)}>
                        <img className={styles.faq_section_svg} src={indexOpen === 6 ? opened_menu : closed_menu} alt="select"/>
                        <p className={styles.faq_section_span}>Подождите немного, идет розыгрыш приза?</p>
                    </div>
                    {indexOpen === 6 ?
                        <div className={styles.faq_section_btm_wrap}>
                            <span className={styles.faq_section_btm_wrap_span}>Банальные, но неопровержимые выводы, а также интерактивные прототипы превращены в посмешище, хотя само их существование приносит несомненную пользу обществу. Однозначно, сторонники тоталитаризма в науке набирают популярность среди определенных слоев населения, а значит, должны быть смешаны с не уникальными данными до степени совершенной неузнаваемости, из-за чего возрастает их статус бесполезности. Банальные, но неопровержимые выводы, а также действия представителей оппозиции призывают нас к новым свершениям, которые, в свою очередь, должны быть объективно рассмотрены соответствующими инстанциями. А ещё реплицированные с зарубежных источников, современные исследования, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут в равной степени предоставлены сами себе. Следует отметить, что современная методология разработки позволяет оценить значение поэтапного и последовательного развития общества.</span>
                        </div> : null}
                </div>
                <div>
                    <div className={styles.faq_section} onClick={() => setIndexOpen(indexOpen === 7 ? 0 : 7)}>
                        <img className={styles.faq_section_svg} src={indexOpen === 7 ? opened_menu : closed_menu} alt="select"/>
                        <p className={styles.faq_section_span}>Подождите немного, идет розыгрыш приза?</p>
                    </div>
                    {indexOpen === 7 ?
                        <div className={styles.faq_section_btm_wrap}>
                            <span className={styles.faq_section_btm_wrap_span}>Банальные, но неопровержимые выводы, а также интерактивные прототипы превращены в посмешище, хотя само их существование приносит несомненную пользу обществу. Однозначно, сторонники тоталитаризма в науке набирают популярность среди определенных слоев населения, а значит, должны быть смешаны с не уникальными данными до степени совершенной неузнаваемости, из-за чего возрастает их статус бесполезности. Банальные, но неопровержимые выводы, а также действия представителей оппозиции призывают нас к новым свершениям, которые, в свою очередь, должны быть объективно рассмотрены соответствующими инстанциями. А ещё реплицированные с зарубежных источников, современные исследования, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут в равной степени предоставлены сами себе. Следует отметить, что современная методология разработки позволяет оценить значение поэтапного и последовательного развития общества.</span>
                        </div> : null}
                </div>
                <div>
                    <div className={styles.faq_section} onClick={() => setIndexOpen(indexOpen === 8 ? 0 : 8)}>
                        <img className={styles.faq_section_svg} src={indexOpen === 8 ? opened_menu : closed_menu} alt="select"/>
                        <p className={styles.faq_section_span}>Подождите немного, идет розыгрыш приза?</p>
                    </div>
                    {indexOpen === 8 ?
                        <div className={styles.faq_section_btm_wrap}>
                            <span className={styles.faq_section_btm_wrap_span}>Банальные, но неопровержимые выводы, а также интерактивные прототипы превращены в посмешище, хотя само их существование приносит несомненную пользу обществу. Однозначно, сторонники тоталитаризма в науке набирают популярность среди определенных слоев населения, а значит, должны быть смешаны с не уникальными данными до степени совершенной неузнаваемости, из-за чего возрастает их статус бесполезности. Банальные, но неопровержимые выводы, а также действия представителей оппозиции призывают нас к новым свершениям, которые, в свою очередь, должны быть объективно рассмотрены соответствующими инстанциями. А ещё реплицированные с зарубежных источников, современные исследования, которые представляют собой яркий пример континентально-европейского типа политической культуры, будут в равной степени предоставлены сами себе. Следует отметить, что современная методология разработки позволяет оценить значение поэтапного и последовательного развития общества.</span>
                        </div> : null}
                </div>
            </section>
        </main>
    )
}