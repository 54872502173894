import { Link } from "react-router-dom";
import menu from "../../../../assets/tabler_menu.png";
import styles from "../css/header.module.css";
import close from "../../../../assets/majesticons_close.svg"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { BalancePoints } from "./balancePoints";
import { BalanceWallet } from "./balanceWallet";
import { Belt } from "./belt";
import { HeaderNavMobileMenu } from "./headerNav";


function MobileMenuOpen({ setIsOpen }: { setIsOpen: Dispatch<SetStateAction<boolean>> }) {
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpen(false); // Закрываем меню
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setIsOpen]);
    return (
        <div ref={menuRef} className={styles.header_mobile_menu_wrapper}>
            <img onClick={() => setIsOpen(false)} className={styles.header_mobile_menu_close} src={close} alt="close" />
            <div className={styles.balance_wrap_mobile}>
                    <BalanceWallet/>
                    <div className={styles.balance_wrap_mobile_delimetr}></div>
                    <Belt showOnMobile={true}/>
                    <BalancePoints/>
                    <div className={styles.balance_wrap_mobile_delimetr}></div>
                    <HeaderNavMobileMenu/>
                </div>
        </div>
    );
}

export function MobileMenu() {
    let [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <>
            {isOpen ? <MobileMenuOpen setIsOpen={setIsOpen} /> : null}
            <img
                src={menu}
                alt="menu"
                className={styles.header_mobile_menu}
                onClick={() => setIsOpen(true)}
            />
        </>
    );
}
