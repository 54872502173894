import React from 'react';
import {Header} from "../components/header/header";
import {Footer} from "../components/footer/footer";

export function Top({ children }: { children: React.ReactNode }) {
    return (
        <>
            <Header/>
            {children}
            <Footer/>
        </>
    );
}
