import notFound from "../../../assets/notFound.webp"
import logo from "../../../assets/logo.svg"
import {Link} from "react-router-dom";
import styles from "./css/notFound.module.css"

export function NotFoundPage() {
    return (
        <main className={styles.notFound_main}>
            <img className={styles.notFound_main_frst_img} src={logo} alt="logo"/>
            <img className={styles.notFound_main_scnd_img} src={notFound} alt="notFound"/>
            <h1>Упс! Страница не найдена!</h1>
            <h2>Вернитесь на главную и попробуйте снова.</h2>
            <Link to="/">
                <div>
                    <p>Вернутся на главную</p>
                </div>
            </Link>
        </main>
    )
}