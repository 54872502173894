import { AvailableBlowsApiResponseType } from "../types/user/availableBlows";
import { PrizeType } from "../types/prize/prize";
import { PrizeSectionType } from "../types/prize/prizeSection";
import { TelegramUserType } from "../types/user/telegramUser";
import { TelegramUserResponse } from "../types/user/telegramUserResponse";
import { UserApiResponseType } from "../types/user/user";
import { SubscriptionLvlApiResponseType } from "../types/user/subscriptionLvl";
import { PrizeItemApiResponseType } from "../types/prize/prizeItem";
import { FighterApiResponseType } from "../types/user/fighter";
import { WheelApiResponseType } from "../types/prize/wheel";
import { LeadersApiRequestType, LeadersApiResponseType } from "../types/leader/leader";

export default class ApiClient {
    private baseUrl: string;
    private wsUrl: string;
    private ws: WebSocket | null;
  
    constructor() {
      // Инициализация переменных окружения
      this.baseUrl = process.env.REACT_APP_API || 'http://localhost:8000';
      this.wsUrl = process.env.REACT_APP_WS || 'ws://localhost:8000/ws';
      this.ws = null;
    }
  
    // Метод для выполнения GET-запроса
    async get<T>(endpoint: string): Promise<T> {
      try {
        const response = await this.fetchWithRetry<T>(() => fetch(`${this.baseUrl}${endpoint}`, {
          credentials: 'include',
        }));
        return response.json();
      } catch (error) {
        throw new Error(`Ошибка при выполнении GET-запроса: ${error}`);
      }
    }
  
    // Метод для выполнения POST-запроса
    async post<T>(endpoint: string, data: any): Promise<T> {
      try {
        const response = await this.fetchWithRetry<T>(() => fetch(`${this.baseUrl}${endpoint}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          credentials: 'include',
        }));
        return response.json();
      } catch (error) {
        throw new Error(`Ошибка при выполнении POST-запроса: ${error}`);
      }
    }

    private async fetchWithRetry<T>(fetchCall: () => Promise<Response>): Promise<Response> {
      const response = await fetchCall();
      if (response.status === 401) {
        await this.update_access_jwt();
        return fetchCall();
      }
      if (!response.ok) {
        throw new Error(`Ошибка при выполнении запроса: ${response.statusText}`);
      }
      return response;
    }
  
    // Метод для открытия WebSocket соединения
    connectWebSocket(onMessage: (message: any) => void, onError?: (error: Event) => void): void {
      this.ws = new WebSocket(this.wsUrl);
  
      this.ws.onopen = () => {
        console.log('WebSocket соединение открыто');
      };
  
      this.ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        onMessage(message);
      };
  
      this.ws.onerror = (error) => {
        console.error('Ошибка WebSocket соединения', error);
        if (onError) {
          onError(error);
        }
      };
  
      this.ws.onclose = () => {
        console.log('WebSocket соединение закрыто');
      };
    }
  
    // Метод для отправки данных через WebSocket
    sendWebSocketMessage(data: any): void {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        this.ws.send(JSON.stringify(data));
      } else {
        console.error('WebSocket соединение не открыто');
      }
    }
  
    // Метод для закрытия WebSocket соединения
    closeWebSocket(): void {
      if (this.ws) {
        this.ws.close();
        this.ws = null;
      }
    }

    async getPrizes(): Promise<PrizeType> {
        return await this.get<PrizeType>('/prize/all');
    }

    async auth(user: TelegramUserType): Promise<TelegramUserResponse> {
        return await this.post<TelegramUserResponse>('/user/authorize', user);
    }

    async logout() {
      return await this.get('/user/logout');
    }

    async update_access_jwt(){
      return await this.get('/user/update_access_jwt');
    }

    async me(): Promise<UserApiResponseType> {
      return await this.get<UserApiResponseType>('/user/me');
    }
    

    async availableBlows(): Promise<AvailableBlowsApiResponseType> {
      return await this.get<AvailableBlowsApiResponseType>('/user/available_blows');
    }

    async subscriptionLvl(): Promise<SubscriptionLvlApiResponseType> {
      return await this.get<SubscriptionLvlApiResponseType>('/user/subscription_lvl');
    }

    async lastWinnerItems(): Promise<PrizeItemApiResponseType>{
       return await this.get<PrizeItemApiResponseType>('/prize/last_winner_items');
    }

    async allNoSection(): Promise<PrizeItemApiResponseType>{
      return await this.get<PrizeItemApiResponseType>('/prize/all_no_section');
   }

   async getFighter(): Promise<FighterApiResponseType> {
    return await this.get<FighterApiResponseType>('/user/fighter');
   }
   async spinWheel(): Promise<WheelApiResponseType> {
    return await this.get<WheelApiResponseType>('/prize/spin_wheel');
   }

   async loadLeaders(data: LeadersApiRequestType): Promise<LeadersApiResponseType> {
    return await this.get<LeadersApiResponseType>(`/leaders/get_leaders?period=${data.period}`);
  }
  }
  