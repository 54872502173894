import {Link} from "react-router-dom";
import styles from "../css/header.module.css"
import {useLocation} from "react-router-dom";

export function HeaderNav() {
    const location = useLocation();
    return (
        <nav>
            <ul className={styles.nav_wrap}>
                <li>
                    <Link to="/" className={`${styles.nav_item} ${location.pathname === "/" ? styles.nav_active : null}`}>Главная</Link>
                </li>
                <li>
                    <Link to="/leaders" className={`${styles.nav_item} ${location.pathname === "/leaders" ? styles.nav_active : null}`}>Лидеры</Link>
                </li>
                <li>
                    <Link to="/instruction" className={`${styles.nav_item} ${location.pathname === "/instruction" ? styles.nav_active : null}`}>Инструкции</Link>
                </li>
                <li>
                    <Link to="/faq" className={`${styles.nav_item} ${location.pathname === "/faq" ? styles.nav_active : null}`}>FAQ</Link>
                </li>
                
            </ul>
        </nav>
    );
}

export function HeaderNavMobileMenu() {
    const location = useLocation();
    return (
        <nav>
            <ul className={styles.nav_wrap_mobile_menu}>
                <li>
                    <Link to="/" className={`${styles.nav_item} ${location.pathname === "/" ? styles.nav_active : null}`}>Главная</Link>
                </li>
                <li>
                    <Link to="/leaders" className={`${styles.nav_item} ${location.pathname === "/leaders" ? styles.nav_active : null}`}>Лидеры</Link>
                </li>
                <li>
                    <Link to="/instruction" className={`${styles.nav_item} ${location.pathname === "/instruction" ? styles.nav_active : null}`}>Инструкции</Link>
                </li>
                <li>
                    <Link to="/faq" className={`${styles.nav_item} ${location.pathname === "/faq" ? styles.nav_active : null}`}>FAQ</Link>
                </li>
            </ul>
        </nav>
    );
}