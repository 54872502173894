import { PrizeItemType } from "../../../../types/prize/prizeItem";
import styles from "../css/liveLenta.module.css"


export function Item({ name, color, path_to_file }: PrizeItemType) {
    let style_bg;
    let style_shadow;
    switch (color) {
        case "yellow":
            style_bg = styles.item_wrap_yellow;
            style_shadow = styles.item_top_div_yellow;
            break;
        case "blue":
            style_bg = styles.item_wrap_blue;
            style_shadow = styles.item_top_div_blue;
            break;
        case "red":
            style_bg = styles.item_wrap_red;
            style_shadow = styles.item_top_div_red;
            break;
        case "silver":
            style_bg = styles.item_wrap_silver;
            style_shadow = styles.item_top_div_silver;
            break;
        case "burgundy":
            style_bg = styles.item_wrap_burgundy;
            style_shadow = styles.item_top_div_burgundy;
            break;
        case "purple":
            style_bg = styles.item_wrap_purple;
            style_shadow = styles.item_top_div_purple;
            break;
        default:
            style_bg = styles.item_wrap_yellow;
            style_shadow = styles.item_top_div_yellow;
            break
    }
    return (
        <div className={`${styles.item_wrap} ${style_bg}`}>
            <div className={`${styles.item_top_div} ${style_shadow}`}></div>
            <img src={`${process.env.REACT_APP_API}/${path_to_file}`} alt={name} className={styles.item_image}/>
            <p className={styles.item_bottom_text}>{name}</p>
        </div>
    )
}