import { LiveLenta } from "../../components/liveLenta/liveLenta";
import styles from "./css/leaders.module.css";
import box_bg from "../../../assets/box_bg.webp";
import leaders from "../../../assets/leader.png"
import { Leaders } from "../../components/leaders/leaders";

export const LeadersPage = () => {
    return (
        <main>
            <LiveLenta/>
            <img src={box_bg} alt="box_bg" className={styles.box_bg}/>
            <div className={styles.leaders_header_wrap}>
                <img src={leaders} alt="leaders"/>
                <h1 className={styles.leaders_header}>Лидеры</h1>
            </div>
            <Leaders/>
        </main>
    )
}