import { useEffect, useState } from "react";
import ApiClient from "../../../../api/basic";
import coin from "../../../../assets/coins.svg"
import useAccountStore from "../../../../store/account";
import styles from "../css/header.module.css"

export function BalancePoints() {
    const accountStore = useAccountStore();
    const apiClient = new ApiClient();
    const [avaibleBlows, setAvailableBlows] = useState(0)
    useEffect(() => {
        apiClient.availableBlows().then((resp) => {
            if (!resp.success){
                console.log(`Error load available blows: ${resp}`)
                return
            }
            setAvailableBlows(resp.data.count)
        })
    }, [accountStore])
    return (
        <div className={styles.coin_wrap}>
            <div className={styles.coin_wrap_item_top}>
                <img src={coin} alt="coin"/>
                <span className={styles.coin_wrap_item_top_gold}>{accountStore.user.coins}</span>
            </div>
            <span className={styles.coin_wrap_item_bottom}>
                У вас<span className={styles.coin_wrap_item_bottom_white}>{avaibleBlows}</span>удар{avaibleBlows > 5 ? "ов" : "а"}
            </span>
        </div>
    )
}