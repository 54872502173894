import {Link} from "react-router-dom";
import visa from "../../../assets/visa.svg"
import mastercard from "../../../assets/mastercard.svg"
import qiwi from "../../../assets/qiwi.svg"
import webmoney from "../../../assets/webmoney.svg"
import youtube from "../../../assets/youtube.svg"
import vk from "../../../assets/vk.svg"
import tg from "../../../assets/telegram.svg"
import box_glove from "../../../assets/box_gloves.png"
import styles from "./css/footer.module.css"
import { useLocation } from 'react-router-dom';

export function Footer() {
    const location = useLocation();
    return (
        <footer className={styles.footer_wrap}>
            <img src={box_glove} alt="" className={styles.footer_glove_image}/>
            <div className={styles.footer_medium_wrap}>
                <nav>
                    <ul className={styles.footer_nav}>
                        <li>
                            <Link to="/" className={`${styles.footer_nav_item} ${location.pathname === "/" ? styles.footer_nav_item_active : null}`}>Главная</Link>
                        </li>
                        <li>
                            <Link to="/instruction" className={`${styles.footer_nav_item} ${location.pathname === "/instruction" ? styles.footer_nav_item_active : null}`}>Инструкция</Link>
                        </li>
                        <li>
                            <Link to="/faq" className={`${styles.footer_nav_item} ${location.pathname === "/faq" ? styles.footer_nav_item_active : null}`}>FAQ</Link>
                        </li>
                        <li>
                            <Link to="/politics" className={`${styles.footer_nav_item} ${location.pathname === "/politics" ? styles.footer_nav_item_active : null}`}>Политика конфиденциальности</Link>
                        </li>
                    </ul>
                </nav>
                <div className={styles.footer_medium_bottom}>
                    <img className={styles.footer_medium_bottom_visa} src={visa} alt="visa"/>
                    <img className={styles.footer_medium_bottom_mastercard} src={mastercard} alt="mastercard"/>
                    <img className={styles.footer_medium_bottom_qiwi} src={qiwi} alt="qiwi"/>
                    <img className={styles.footer_medium_bottom_webmoney} src={webmoney} alt="webmoney"/>
                </div>
            </div>
            <div className={styles.footer_end_wrap}>
                <span className={styles.footer_end_wrap_span}>Мы в соц. сетях</span>
                <div className={styles.footer_end_bottom_wrap}>
                    <Link to="https://www.youtube.com/@BaLME-24/videos">
                        <img className={styles.footer_end_bottom_wrap_item_youtube} src={youtube} alt="youtube"/>
                    </Link>
                    <Link to="https://vk.com/id369345526">
                        <img className={styles.footer_end_bottom_wrap_item_vk} src={vk} alt="vk"/>
                    </Link>
                    <Link to="https://t.me/BALMEEE">
                        <img className={styles.footer_end_bottom_wrap_item_telegram} src={tg} alt="telegram"/>
                    </Link>
                </div>
            </div>
        </footer>
    )
}