import styles from "../css/home.module.css"
import {Items} from "./items";
import boxing_glove from "../../../../assets/boxing_glove.png"
import {Pagination} from "./pagination";
import {PrizePopUp} from "../../popUpPrizes/prize";
import {useEffect, useState} from "react";
import ApiClient from "../../../../api/basic";
import { PrizeItemType } from "../../../../types/prize/prizeItem";
import useAccountStore from "../../../../store/account";



export function LeftWrap() {
    const [isOpen, setIsOpen] = useState(false)
    const [start, setStart] = useState(0)
    const [items, setItems] = useState<PrizeItemType[]>([])
    const apiClient = new ApiClient()
    useEffect(() => {
        apiClient.allNoSection().then((resp) => {
            if (!resp.success){
                console.log(`Error loading prizes: ${resp}`)
            }
            else{
                setItems(resp.data)
            }
        })
    }, [])
    return (
        <article>
            <h1 className={styles.main_top_h1}>Бей, чтобы получить приз!</h1>
            <Items start={start} items={items}/>
            <Pagination start={start} setStart={setStart} countItems={items.length}/>
            <div onClick={() => setIsOpen(true)} className={styles.boxing_glove_wrap}>
                <img src={boxing_glove} alt="boxing glove" className={styles.boxing_glove}/>
            </div>
            <PrizePopUp setIsOpen={setIsOpen} isOpen={isOpen}/>
        </article>
    )
}