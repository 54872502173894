import {Top} from "./layout/wrap/top";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {HomePage} from "./layout/pages/home/home";
import {FaqPage} from "./layout/pages/faq/faq";
import {InstructionPage} from "./layout/pages/instruction/instruction";
import {NotFoundPage} from "./layout/pages/notFoundPage/notFound";
import {PoliticsPage} from "./layout/pages/politics/politics";
import {PrizePage} from "./layout/pages/prizes/prize";
import ApiClient from "./api/basic";
import useAccountStore from "./store/account";
import { useEffect } from "react";
import { LeadersPage } from "./layout/pages/leaders/leaders";
import { AuthPage } from "./layout/pages/noAuth/notAuth";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function App() {
  const apiClient = new ApiClient();
  const accountStore = useAccountStore();
  useEffect(() => {
      apiClient.me().then((resp) => {
          if (!resp.success){
            console.log(`Error load user: ${resp}`)
          }
          accountStore.setUser(resp.data.user)
          accountStore.setIsAuth(true)
      }).catch((error) => {
          console.log(`Error loading prizes: ${error}`)
      })
  }, [])
  return (
      <Router>
        <Top>
            <Routes>
                {accountStore.is_auth ? <Route path="/" element={<HomePage />} /> : <Route path="/" element={<AuthPage />} />}
                <Route path="/prizes" element={<PrizePage />} />
                <Route path="/faq" element={<FaqPage />} />
                <Route path="/instruction" element={<InstructionPage />} />
                <Route path="/politics" element={<PoliticsPage />} />
                <Route path="/leaders" element={<LeadersPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Top>
        <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      </Router>
  );
}

export default App;
