import styles from "../css/home.module.css"
import { ItemProps, PrizeItemType } from "../../../../types/prize/prizeItem"
import medal_1 from "../../../../assets/medal1.png"
import medal_2 from "../../../../assets/medal2.png"
import medal_3 from "../../../../assets/medal3.png"
import { useState } from "react"


function MedalHover({lvl}: {lvl?: number}) {
    return (
        <div className={styles.locked_overlay_wrap}>
            <span className={styles.locked_overlay_text_top}>Данный подарок доступен<br/>
            со <span>{lvl} уровня</span> подписки</span>
            <u className={styles.locked_overlay_text_bottom}><a href={`https://t.me/${process.env.REACT_TELEGRAM_BOT}`}>Оформить подписку</a></u>
        </div>
    )
}


export function Item({color, name, path_to_file, lvl, isLocked, size = 'medium'}: ItemProps) {
    let style_bg = styles.main_item_yellow
    const [isHovered, setIsHovered] = useState(false)
    let style_bottom_bg = styles.main_item_bottom_div_yellow
    let medalLvl = undefined
    switch (color) {
        case "yellow":
            style_bg = styles.main_item_yellow;
            style_bottom_bg = styles.main_item_bottom_div_yellow;
            break;
        case "blue":
            style_bg = styles.main_item_blue;
            style_bottom_bg = styles.main_item_bottom_div_blue;
            break;
        case "red":
            style_bg = styles.main_item_red;
            style_bottom_bg = styles.main_item_bottom_div_red;
            break;
        case "purple":
            style_bg = styles.main_item_purple;
            style_bottom_bg = styles.main_item_bottom_div_purple;
            break;
        case "silver":
            style_bg = styles.main_item_silver;
            style_bottom_bg = styles.main_item_bottom_div_silver;
            break;
        default:
            style_bg = styles.main_item_inactive_yellow;
            style_bottom_bg = styles.main_item_bottom_div_yellow_inactive;
    }
    switch (lvl) {
        case 1:
            medalLvl = medal_3
            break
        case 2:
            medalLvl = medal_2
            break
        case 3:
            medalLvl = medal_1
            break
    }
    let style_main_item_size = styles.main_item_medium
    switch (size) {
        case 'small':
            style_main_item_size = styles.main_item_small
            break
        case 'medium':
            style_main_item_size = styles.main_item_medium
            break
        case 'large':
            style_main_item_size = styles.main_item_large
            break
    }
    let style_main_item_medal_size = styles.main_item_medal_medium
    switch (size) {
        case 'small':
            style_main_item_medal_size = styles.main_item_medal_small
            break   
        case 'medium':
            style_main_item_medal_size = styles.main_item_medal_medium
            break
        case 'large':
            style_main_item_medal_size = styles.main_item_medal_large
            break
    }
    let style_main_item_bottom_text = styles.main_item_bottom_text_medium
    switch (size) {
        case 'small':
            style_main_item_bottom_text = styles.main_item_bottom_text_small
            break
        case 'medium':
            style_main_item_bottom_text = styles.main_item_bottom_text_medium
            break
        case 'large':
            style_main_item_bottom_text = styles.main_item_bottom_text_large
            break
    }
    let style_main_item_bottom_div = styles.main_item_bottom_div_medium
    switch (size) {
        case 'small':
            style_main_item_bottom_div = styles.main_item_bottom_div_small
            break
        case 'medium':
            style_main_item_bottom_div = styles.main_item_bottom_div_medium
            break
        case 'large':
            style_main_item_bottom_div = styles.main_item_bottom_div_large
            break
    }
    return (
        <div className={styles.main_item_wrap}
        onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <div className={`${isLocked && styles.main_item_bottom_div_lcoked} ${style_main_item_size} ${style_bg}`}>
                <img src={medalLvl} alt={`medal ${lvl}`} className={style_main_item_medal_size}/>
                <img src={`${process.env.REACT_APP_API}/${path_to_file}`} alt={name}/>
                <div className={`${style_main_item_bottom_div} ${style_bottom_bg}`}>
                    <p className={style_main_item_bottom_text}>{name}</p>
                </div>
            </div>
            {isLocked && isHovered && (
                <MedalHover lvl={lvl}/>
            )}
        </div>
    )
}