import { useEffect, useState } from "react";
import useAccountStore from "../../../../store/account";
import styles from "../css/header.module.css"
import ApiClient from "../../../../api/basic";


export function Profile() {
    const accountStore = useAccountStore();
    const apiClient = new ApiClient();
    const [suscriptionLvl, setSuscriptionLvl] = useState(0)
    useEffect(() => {
        apiClient.subscriptionLvl().then((resp) => {
            if (!resp.success){
                console.log(`Error load suscription lvl: ${resp}`)
                return
            }
            setSuscriptionLvl(resp.data.lvl)
        })
    })
    return (
        <div className={styles.profile_wrap}>
            <div className={styles.profile_user_wrap}>
                <span className={styles.profile_user_wrap_top_text}>@{accountStore.user.username}</span>
                <div className={styles.profile_user_wrap_bottom_wrap}>
                    <span className={styles.profile_user_wrap_bottom_wrap_text}>Подписка {suscriptionLvl} lvl</span>
                </div>
            </div>
            <img src={`data:image/png;base64, ${accountStore.user.avatar}`} alt="profile img" className={styles.profile_user_icon}/>
        </div>
    )
}